import { FillDay, TranslateKeys } from '@og-shared/types';

export const OG_APP_STORE_URL =
  'https://apps.apple.com/us/app/one-goal-finance/id1572722093' as const;
export const OG_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.onegoal.onegoal' as const;

export const IGNORE_BUDGET_ID = 'IGNORE' as const;

export const UNCATEGORIZED_BUDGET_ID = 'UNCATEGORIZED' as const;

export const fillDays: TranslateKeys[] = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export function getFillDayString(fillDay: FillDay): TranslateKeys {
  return fillDays[fillDay] ?? 'SUNDAY';
}

export const ogDemoOne = 'one-goal-demo-1' as const;

export function isDemoGroupId(id: string | null) {
  if (!id) return false;
  const demoGroups: string[] = [ogDemoOne];
  return demoGroups.includes(id);
}
